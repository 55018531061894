import Stack from "@mui/material/Stack";
import Paper, { PaperProps } from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

type ManagePaperProps = Partial<PaperProps> & {
  sectionTitle: string;
  Button?: ReactNode;
};

export function ManagePaper({
  children,
  sectionTitle,
  Button,
  ...rest
}: ManagePaperProps) {
  return (
    <Paper
      {...rest}
      sx={{ py: 6, px: 4, mb: 6, overflow: "hidden", ...rest.sx }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography variant="h5">{sectionTitle}</Typography>
        {Button}
      </Stack>
      {children}
    </Paper>
  );
}
