export function determineSlideDirection<T>(
  currentIndex: number,
  options: T[],
  previousSelectedOption: T,
  currentlySelectedOption: T
): "left" | "right" {
  const isTabMovingRight =
    options.indexOf(previousSelectedOption) <
    options.indexOf(currentlySelectedOption);
  const isEntering = currentIndex === options.indexOf(currentlySelectedOption);
  if (isEntering) {
    return isTabMovingRight ? "right" : "left";
  }
  return isTabMovingRight ? "left" : "right";
}
