import { useDMSFormErrorHandler, useCategories } from "features/DMS/hooks";
import Typography from "@mui/material/Typography";
import { useAPIPostJSON } from "core/hooks/useAPIPostJSON";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CategoryForm, defaultValues } from "./CategoryForm";
import { CategoryFormValues } from "../entities";

interface CategoryAddProps {
  cancelOnClick: () => void;
  docLibraryId: string;
  canManageLibraryContents: boolean;
}
export function CategoryAdd({
  cancelOnClick,
  docLibraryId,
  canManageLibraryContents,
}: CategoryAddProps) {
  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const { mutate } = useCategories({
    parentId: docLibraryId,
    mode: "manage",
    canManageLibraryContents,
  });
  const apiPost = useAPIPostJSON();
  const { successToast, errorToast } = useToaster();
  const { t } = useTranslation("DocumentManagementSystem");
  const { errorHandler, scrollToRef } = useDMSFormErrorHandler(
    formMethods,
    "CategoryForm"
  );

  async function onValid(data: CategoryFormValues) {
    try {
      const { translations: translationObject, ...rest } = data;
      const translations = Object.values(translationObject);
      const newCategory = await apiPost("doc-category", {
        docLibraryId,
        translations,
        ...rest,
      });
      mutate((currentCategories) =>
        currentCategories && Array.isArray(currentCategories?.items)
          ? {
              items: [...currentCategories.items, newCategory],
              totalCount: currentCategories.totalCount + 1,
            }
          : { items: [newCategory], totalCount: 1 }
      );
      successToast(t("Next:DocumentManagementSystem:CategoryAdd.POSTSuccess"));
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:CategoryAdd.POSTError"));
    }
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        color="primary.main"
        my={3}
        ref={scrollToRef}
      >
        {t("Next:DocumentManagementSystem:CategoryAdd.title")}
      </Typography>
      <CategoryForm
        formMethods={formMethods}
        onSubmit={handleSubmit(onValid, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
        mode="add"
        formType="CategoryForm"
        isSubmitting={isSubmitting}
      />
    </>
  );
}
