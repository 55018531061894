import { useCallback } from "react";
import Avatar from "@mui/material/Avatar";
import {
  useDownloadDocumentByFileId,
  useDmsDocumentHref,
} from "features/DMS/hooks";
import MUILink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { useUserLog } from "core/hooks/useUserLog";
import { SvgFileIcon } from "core/components/SvgFileIcon";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { useNotifications } from "features/Notifications/useNotifications";
import {
  DMSWidgetListItemTemplate,
  SharedDMSWidgetListItemProps,
} from "./DMSWidgetListItemTemplate";
import { DMSWidgetDocumentListItemButtons } from "./DMSWidgetDocumentListItemButtons";

export interface DMSWidgetFileListItemProps
  extends SharedDMSWidgetListItemProps {
  id: string;
  title: string;
  fileId: string;
  fileName: string;
}

export function DMSWidgetFileListItem({
  id,
  title,
  fileId,
  fileName,
  sx,
  dateLabel,
  dateText,
  parents,
  ...rest
}: DMSWidgetFileListItemProps) {
  const { addLog } = useUserLog();
  const downloadDocument = useDownloadDocumentByFileId();
  const { markNotificationAsReadByItemId } = useNotifications();
  const addDocumentLog = useCallback(async () => {
    await Promise.all([
      addLog("DmsDocument", id),
      markNotificationAsReadByItemId(id),
    ]);
  }, [id, addLog, markNotificationAsReadByItemId]);
  const downloadListItemFile = useCallback(async () => {
    addDocumentLog();
    await downloadDocument(fileId, fileName);
  }, [fileId, fileName, downloadDocument, addDocumentLog]);
  const extension = fileName.split(".").at(-1);
  const isPDF = extension === "pdf";
  const { isMobile } = useBreakpoints();
  const fileHref = useDmsDocumentHref(fileId, fileName);
  return (
    <DMSWidgetListItemTemplate
      id={id}
      {...rest}
      dateText={dateText}
      dateLabel={dateLabel}
      parents={parents}
      sx={sx}
      AvatarSlot={
        <Avatar
          onClick={downloadListItemFile}
          sx={{
            bgcolor: "divider",
            color: (theme) => theme.palette.text.secondary,
            "&:hover": { cursor: "pointer" },
          }}
        >
          <SvgFileIcon fileName={fileName} />
        </Avatar>
      }
      TitleSlot={
        <MUILink
          href={fileHref}
          target="_blank"
          underline="none"
          onClick={addDocumentLog}
        >
          <Typography variant="body1" sx={{ pb: 1 }} fontWeight="bold">
            {title} <NotificationDot id={id} mode="self" />
          </Typography>
        </MUILink>
      }
      ButtonSlot={
        <DMSWidgetDocumentListItemButtons
          download={downloadListItemFile}
          onLinkClick={addDocumentLog}
          visitHref={fileHref}
          isMobile={isMobile}
          isPDF={isPDF}
        />
      }
    />
  );
}
