export function removeNullAndEmptyStringValuesFromObject(object: {
  [x: string]: any;
}) {
  return Object.fromEntries(
    Object.entries(object).filter(({ 1: value }) => {
      if (typeof value === "string") {
        return !!value;
      }
      return value != null;
    })
  );
}
