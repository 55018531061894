/**
 *
 * @param array an array of objects
 * @param keyProperty property on the array of objects to use as the resulting map's key value
 * @returns an object with the provided keyProperty value as the keys
 */
export function arrayToObject<T extends Record<string, any>>(
  array: T[] = [],
  keyProperty = "id",
  subProperty: string | undefined = undefined
): Record<string, T> {
  return Object.fromEntries(
    array
      .filter(
        (entry) =>
          keyProperty in
          (subProperty === undefined ? entry : entry[subProperty])
      )
      .map((entry) => [
        subProperty === undefined
          ? entry[keyProperty]
          : entry[subProperty][keyProperty],
        entry,
      ])
  );
}
