import { DmsDocumentTileGrid } from "./DmsDocumentTileGrid";
import { DMSDocumentList } from "./DmsDocumentList";
import { SharedDmsDocumentDisplayProps } from "./DmsDocumentDisplayDefs";

export interface DmsDocumentDisplayProps extends SharedDmsDocumentDisplayProps {
  viewMode: "tile" | "list";
}

export function DmsDocumentDisplay({
  viewMode,
  docLibraryId,
  documents = [],
  loadMoreOnClick,
  addDocumentOnClick,
  showLoadMore,
  isLoadingMore,
  showPermissions,
}: DmsDocumentDisplayProps) {
  if (viewMode === "tile") {
    return (
      <DmsDocumentTileGrid
        docLibraryId={docLibraryId}
        addDocumentOnClick={addDocumentOnClick}
        documents={documents}
        showLoadMore={showLoadMore}
        isLoadingMore={isLoadingMore}
        showPermissions={showPermissions}
        loadMoreOnClick={loadMoreOnClick}
      />
    );
  }
  return (
    <DMSDocumentList
      addDocumentOnClick={addDocumentOnClick}
      docLibraryId={docLibraryId}
      documents={documents}
      showLoadMore={showLoadMore}
      isLoadingMore={isLoadingMore}
      showPermissions={showPermissions}
      loadMoreOnClick={loadMoreOnClick}
    />
  );
}
