import { MultiSelect } from "core/components/MultiSelect";
import { Control, useController } from "react-hook-form";
import { ALL_VALUE } from "config";

export interface PermissionMultiSelectProps {
  name: string;
  label: string;
  options: { label: string; value: any }[];
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  control?: Control<any, any>;
}

export function PermissionMultiSelect({
  name,
  label,
  options,
  disabled = false,
  loading = false,
  loadingText,
  control,
}: PermissionMultiSelectProps) {
  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
    rules: { required: "Next:Core:FieldRequiredValidationMessage" },
  });

  return (
    <MultiSelect
      options={loading ? [] : options}
      label={label}
      values={value}
      disabled={disabled}
      loading={loading}
      loadingText={loadingText}
      onChange={(e, selectedOptions) => {
        const lastSelected = selectedOptions.at(-1);
        if (lastSelected?.value === ALL_VALUE) {
          onChange([lastSelected]);
          return;
        }
        const filteredSelections = selectedOptions.filter(
          ({ value: currentValue }: { value: any }) =>
            currentValue !== ALL_VALUE
        );
        onChange(filteredSelections);
      }}
    />
  );
}
