import Avatar from "@mui/material/Avatar";
import { Link } from "core/components/Link";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { useUserLog } from "core/hooks/useUserLog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";
import { useNotifications } from "features/Notifications/useNotifications";
import { NotificationDot } from "features/Notifications/NotificationDot";
import {
  DMSWidgetListItemTemplate,
  SharedDMSWidgetListItemProps,
} from "./DMSWidgetListItemTemplate";

export interface DMSWidgetLinkListItemProps
  extends SharedDMSWidgetListItemProps {
  id: string;
  title: string;
  documentLink: string;
}

export function DMSWidgetLinkListItem({
  id,
  title,
  dateText,
  dateLabel,
  documentLink,
  tags,
  parents,
  sx,
}: DMSWidgetLinkListItemProps) {
  const { addLog } = useUserLog();
  const { markNotificationAsReadByItemId } = useNotifications();
  async function onLinkClick() {
    await Promise.all([
      addLog("DmsDocument", id),
      markNotificationAsReadByItemId(id),
    ]);
  }
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DMSWidgetLinkListItem",
  });
  return (
    <DMSWidgetListItemTemplate
      id={id}
      parents={parents}
      dateLabel={dateLabel}
      dateText={dateText}
      tags={tags}
      AvatarSlot={
        <Link
          onClick={onLinkClick}
          href={documentLink}
          target="_blank"
          passHref={false}
        >
          <Avatar
            sx={{
              bgcolor: "divider",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <OpenInNew color="inherit" />
          </Avatar>
        </Link>
      }
      TitleSlot={
        <Link onClick={onLinkClick} href={documentLink} target="_blank">
          <Typography variant="body1" sx={{ pb: 1 }} fontWeight="bold">
            {title} <NotificationDot mode="self" id={id} />
          </Typography>
        </Link>
      }
      ButtonSlot={
        isMobile ? (
          <Button
            color="primary"
            target="_blank"
            onClick={onLinkClick}
            href={documentLink}
            LinkComponent={Link}
          >
            <ChevronRight />
          </Button>
        ) : (
          <Button
            target="_blank"
            onClick={onLinkClick}
            href={documentLink}
            LinkComponent={Link}
            endIcon={<ChevronRight />}
          >
            {t("visit")}
          </Button>
        )
      }
      sx={sx}
    />
  );
}
