import ListItem, { ListItemProps } from "@mui/material/ListItem";

export function StyledListItem({ ...props }: ListItemProps) {
  return (
    <ListItem
      {...props}
      sx={{
        mb: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        ...props.sx,
      }}
    />
  );
}
