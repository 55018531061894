import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Modal, { ModalProps } from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

export function CustomModal({ children, ...props }: ModalProps) {
  return (
    <Modal {...props}>
      <Box position="relative">
        {children}
        <Fab
          onClick={() => {
            if (typeof props.onClose === "function") {
              props.onClose({}, "backdropClick");
            }
          }}
          size="small"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            transform: "translate(50%, -50%)",
            boxShadow: 1,
          }}
        >
          <CloseIcon />
        </Fab>
      </Box>
    </Modal>
  );
}
