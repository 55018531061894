import { ReactHookFormSelect } from "core/components/ReactHookFormComponents/ReactHookFormSelect";
import { useMenuItems } from "core/hooks/useMenuItems";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { ContainerTranslationsFields } from "./TranslationsFields";

export function LibraryFormDetails() {
  const { t } = useTranslation("DocumentManagementSystem");
  const { data: menuItems } = useMenuItems();
  const menuOptions =
    menuItems?.map(({ displayName, id }) => ({
      label: displayName,
      value: id,
    })) || [];
  return (
    <Stack spacing={2} overflow="hidden">
      <ContainerTranslationsFields formType="LibraryForm" />
      <ReactHookFormSelect
        name="menuItemId"
        required
        fullWidth
        options={menuOptions}
        label={t("Next:DocumentManagementSystem:LibraryForm.menuItemId")}
      />
    </Stack>
  );
}
