import { useState } from "react";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LibraryFormValues, IDocLibraryDto } from "features/DMS/entities";
import { useAPIPostJSON } from "core/hooks/useAPIPostJSON";
import { useToaster } from "core/hooks/useToaster";
import {
  useDMSFormErrorHandler,
  useManageableLibraries,
} from "features/DMS/hooks";
import { LibraryForm, defaultValues } from "./LibraryForm";

export interface LibraryAddProps {
  cancelOnClick: () => void;
}

export function LibraryAdd({ cancelOnClick }: LibraryAddProps) {
  const formMethods = useForm<LibraryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit } = formMethods;
  const { mutate } = useManageableLibraries();
  const apiPost = useAPIPostJSON();
  const { successToast, errorToast } = useToaster();
  const { t } = useTranslation("DocumentManagementSystem");
  const { errorHandler, scrollToRef } = useDMSFormErrorHandler(
    formMethods,
    "LibraryForm"
  );
  async function handleFormSubmit(data: LibraryFormValues) {
    setIsSubmitting(true);
    try {
      const { translations: transObject, ...rest } = data;
      const translations = Object.values(transObject);
      // POST library data
      const newLibrary = await apiPost<IDocLibraryDto>("doc-library", {
        translations,
        ...rest,
      });
      // update current list of libraries to include the newly created library
      const mutateLibrariesCallback: Parameters<typeof mutate>[0] = (
        currentLibraries
      ) =>
        currentLibraries && Array.isArray(currentLibraries.items)
          ? {
              items: [...currentLibraries.items, newLibrary],
              totalCount: currentLibraries.totalCount + 1,
            }
          : { items: [newLibrary], totalCount: 1 };
      mutate(mutateLibrariesCallback);
      successToast(t("Next:DocumentManagementSystem:LibraryAdd.POSTSuccess"));
      cancelOnClick();
    } catch {
      setIsSubmitting(false);
      errorToast(t("Next:DocumentManagementSystem:LibraryAdd.POSTError"));
    }
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        color="primary.main"
        my={3}
        ref={scrollToRef}
      >
        {t("Next:DocumentManagementSystem:LibraryAdd.title")}
      </Typography>
      <LibraryForm
        isSubmitting={isSubmitting}
        formMethods={formMethods}
        onSubmit={handleSubmit(handleFormSubmit, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
      />
    </>
  );
}
