import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";

function SkeletonRounded(props: SkeletonProps) {
  const { sx } = props;
  return <Skeleton {...props} sx={{ borderRadius: 2, ...sx }} />;
}

export function LibraryFormDetailsSkeleton() {
  return (
    <Stack spacing={2}>
      <SkeletonRounded variant="rectangular" height="50px" width="100px" />
      <SkeletonRounded variant="rectangular" width="100%">
        <TextField />
      </SkeletonRounded>
      <SkeletonRounded variant="rectangular" width="100%">
        <TextField multiline minRows={6} />
      </SkeletonRounded>
      <SkeletonRounded variant="rectangular" width="100%">
        <TextField />
      </SkeletonRounded>
      <SkeletonRounded variant="rectangular" width="100%">
        <TextField />
      </SkeletonRounded>
    </Stack>
  );
}
