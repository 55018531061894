import { useMenuActions } from "features/DMS/hooks";
import { useMemo, ReactNode, createContext } from "react";
import { DmsActionModal } from "./DmsActionModal";
import { MenuActions } from "../entities";

type DmsModalContextValue = {
  libraryId: string;
  openEditLibraryModal: () => void;
  openDeleteLibraryModal: () => void;
  openAddDocumentToLibraryModal: () => void;
  openAddCategoryToLibraryModal: () => void;
  openEditDocumentModal: (id: string, parentId: string) => void;
  openDeleteDocumentModal: (id: string, parentId: string) => void;
  closeModal: () => void;
  openModalAction: ({
    id,
    parentId,
    docLibraryId,
    action,
  }: {
    id?: string | undefined;
    parentId?: string | undefined;
    docLibraryId?: string | undefined;
    action: MenuActions;
  }) => void;
};

export const DmsModalContext = createContext<DmsModalContextValue>({
  libraryId: "",
  openEditLibraryModal: () => {},
  openDeleteLibraryModal: () => {},
  openAddDocumentToLibraryModal: () => {},
  openAddCategoryToLibraryModal: () => {},
  openEditDocumentModal: () => {},
  openDeleteDocumentModal: () => {},
  closeModal: () => {},
  openModalAction: () => {},
});

export function DmsModalContextProvider({
  children,
  libraryId,
  canManageLibraryContents,
}: {
  children: ReactNode;
  libraryId: string;
  canManageLibraryContents: boolean;
}) {
  const { ActionModalProps, openModalAction, closeModal } = useMenuActions();
  const providerValue = useMemo(() => {
    function openEditLibraryModal() {
      openModalAction({
        action: "editLibrary",
        id: libraryId,
      });
    }
    function openDeleteLibraryModal() {
      openModalAction({
        action: "deleteLibrary",
        id: libraryId,
      });
    }
    function openAddDocumentToLibraryModal() {
      openModalAction({
        action: "addDocument",
        id: libraryId,
        parentId: libraryId,
        docLibraryId: libraryId,
      });
    }
    function openAddCategoryToLibraryModal() {
      openModalAction({
        docLibraryId: libraryId,
        parentId: libraryId,
        action: "addCategory",
      });
    }

    function openEditDocumentModal(id: string, parentId: string) {
      openModalAction({
        id,
        parentId,
        docLibraryId: libraryId,
        action: "editDocument",
      });
    }

    function openDeleteDocumentModal(id: string, parentId: string) {
      openModalAction({
        id,
        parentId,
        docLibraryId: libraryId,
        action: "deleteDocument",
      });
    }

    return {
      openEditLibraryModal,
      openDeleteLibraryModal,
      openAddDocumentToLibraryModal,
      openAddCategoryToLibraryModal,
      openEditDocumentModal,
      openDeleteDocumentModal,
      closeModal,
      libraryId,
      openModalAction,
    };
  }, [libraryId, closeModal, openModalAction]);
  return (
    <>
      <DmsActionModal
        canManageLibraryContents={canManageLibraryContents}
        {...ActionModalProps}
      />
      <DmsModalContext.Provider value={providerValue}>
        {children}
      </DmsModalContext.Provider>
    </>
  );
}
