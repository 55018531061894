import Typography from "@mui/material/Typography";
import { useAPIPostJSON } from "core/hooks/useAPIPostJSON";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CategoryFormValues } from "../entities";
import { useCategories, useDMSFormErrorHandler } from "../hooks";
import { CategoryForm, defaultValues } from "./CategoryForm";

interface SubCategoryAddProps {
  cancelOnClick: () => void;
  parentId: string;
  docLibraryId: string;
  canManageLibraryContents: boolean;
}

export function SubCategoryAdd({
  cancelOnClick,
  parentId,
  docLibraryId,
  canManageLibraryContents,
}: SubCategoryAddProps) {
  const { t } = useTranslation("DocumentManagementSystem");

  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const { mutate } = useCategories({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const apiPost = useAPIPostJSON();
  const { successToast, errorToast } = useToaster();
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "CategoryForm");

  async function handleFormSubmit(data: CategoryFormValues) {
    try {
      const { translations: translationObject, ...rest } = data;
      const translations = Object.values(translationObject);
      const newSubCategory = await apiPost("doc-category", {
        parentId,
        docLibraryId,
        translations,
        ...rest,
      });
      mutate((currentCategories) =>
        currentCategories && Array.isArray(currentCategories.items)
          ? {
              items: [...currentCategories.items, newSubCategory],
              totalCount: currentCategories.totalCount + 1,
            }
          : { items: [newSubCategory], totalCount: 1 }
      );
      successToast(
        t("Next:DocumentManagementSystem:SubCategoryAdd.POSTSuccess")
      );
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:SubCategoryAdd.POSTError"));
    }
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t("Next:DocumentManagementSystem:SubCategoryAdd.title")}
      </Typography>
      <CategoryForm
        formMethods={formMethods}
        onSubmit={handleSubmit(handleFormSubmit, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
        mode="add"
        formType="SubCategoryForm"
        isSubmitting={isSubmitting}
      />
    </>
  );
}
