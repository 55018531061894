import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export function SectionTitle({ title }: { title: string }) {
  return (
    <>
      <Typography variant="overline" px={2}>
        {title}
      </Typography>
      <Divider sx={{ mb: 3 }} />
    </>
  );
}
