import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Image from "next/legacy/image";
import { useDmsDocumentHref } from "features/DMS/hooks";

export function ImageAvatar({
  thumbnailName,
  thumbnailId,
}: {
  thumbnailName: string;
  thumbnailId: string;
}) {
  const href = useDmsDocumentHref(thumbnailId, thumbnailName);
  return (
    <Tooltip
      title={
        <Image
          height="200"
          width="300"
          objectFit="contain"
          src={href}
          alt="thumbnail image"
        />
      }
    >
      <Avatar>
        <Image
          height="36"
          width="36"
          src={href}
          alt="thumbnail image"
          objectFit="contain"
        />
      </Avatar>
    </Tooltip>
  );
}
