import { MouseEventHandler } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { SubmitButton } from "core/components/SubmitButton";
import { useTranslation } from "react-i18next";

export interface DMSFormButtonsProps {
  mode: "add" | "edit";
  formType: "LibraryForm" | "CategoryForm" | "SubCategoryForm" | "DocumentForm";
  cancelOnClick: MouseEventHandler<HTMLButtonElement>;
  clearOnClick?: MouseEventHandler<HTMLButtonElement>;
  isSubmitting?: boolean;
  submitDisabled?: boolean;
}

export function DMSFormButtons({
  mode,
  formType,
  cancelOnClick,
  clearOnClick,
  isSubmitting,
  submitDisabled = false,
}: DMSFormButtonsProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      <Button variant="outlined" color="error" onClick={cancelOnClick}>
        {t(`Next:DocumentManagementSystem:${formType}.CancelButton`)}
      </Button>
      {mode === "add" && (
        <Button variant="outlined" color="primary" onClick={clearOnClick}>
          {t(`Next:DocumentManagementSystem:${formType}.ClearButton`)}
        </Button>
      )}
      <SubmitButton disabled={submitDisabled} loading={isSubmitting} />
    </Stack>
  );
}
