import { ReactNode } from "react";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch, { switchClasses } from "@mui/material/Switch";

export interface SwitchySwitch2Props<T extends string | number> {
  label?: string;
  labelPlacement?: "top" | "bottom" | "end" | "start" | undefined;
  options: {
    left: { label: string | ReactNode; value: T };
    right: { label: string | ReactNode; value: T };
  };
  value: T;
  sx?: FormControlLabelProps["sx"];
  stackProps?: StackProps;
  onChange: (val: T) => void;
  "data-testid"?: string;
}

export function SwitchySwitch2<T extends string | number>({
  label = "",
  labelPlacement,
  options,
  onChange,
  value,
  sx,
  stackProps,
  "data-testid": dataTestId,
}: SwitchySwitch2Props<T>) {
  const isRight = value === options.right.value;
  function onSwitchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const position = e.target.checked ? "right" : "left";
    onChange(options[position].value);
  }
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      sx={{ ml: 0, ...sx }}
      control={
        <Stack px={2} direction="row" alignItems="center" {...stackProps}>
          {typeof options.left.label === "string" ? (
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: !isRight ? "bold" : "normal" }}
            >
              {options.left.label}
            </Typography>
          ) : (
            options.left.label
          )}
          <Switch
            data-testid={dataTestId}
            checked={value === options.right.value}
            onChange={onSwitchChange}
            sx={{
              [`& .${switchClasses.track}`]: {
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) => theme.palette.primary.main,
              },
              [`& .${switchClasses.thumb}`]: {
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) => theme.palette.primary.main,
              },
            }}
          />
          {typeof options.right.label === "string" ? (
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: isRight ? "bold" : "normal" }}
            >
              {options.right.label}
            </Typography>
          ) : (
            options.right.label
          )}
        </Stack>
      }
    />
  );
}
