import Stack, { StackProps } from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Chip, { ChipProps } from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";

export type TagsDisplayProps = {
  tags: string[];
  numberOfVisibleTags?: number;
  chipProps?: Partial<ChipProps>;
} & Partial<StackProps>;

export function TagsDisplay({
  tags = [],
  direction = "row",
  gap = 1,
  numberOfVisibleTags = 2,
  chipProps,
  ...stackProps
}: TagsDisplayProps) {
  const { t } = useTranslation("Fixhub");
  const visibleTags = tags.slice(0, numberOfVisibleTags);
  const numberOfHiddenTags = tags.length - numberOfVisibleTags;
  return (
    <Tooltip
      title={tags.flatMap((tag, index, { length }) =>
        index + 1 === length
          ? [tag, <br key={tag} />]
          : [tag, ",", <br key={tag} />]
      )}
    >
      <Stack
        sx={{ "& :hover": { cursor: "pointer" } }}
        direction={direction}
        gap={gap}
        flexWrap="wrap"
        {...stackProps}
      >
        {visibleTags.map((label) => (
          <Chip
            variant="filled"
            color="primary"
            key={label}
            label={capitalize(label)}
            {...chipProps}
          />
        ))}
        {numberOfHiddenTags > 0 && (
          <Chip
            variant="outlined"
            label={t("Next:Core:TagsDisplay.numberOfHiddenTags", {
              count: numberOfHiddenTags,
            })}
            {...chipProps}
          />
        )}
      </Stack>
    </Tooltip>
  );
}
