import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { useIsEditMode } from "features/DMS/hooks";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SharedDmsDocumentDisplayProps } from "../DmsDocumentDisplayDefs";
import { DmsDocumentTile } from "./DmsDocumentTile";

export function DmsDocumentTileGrid({
  documents = [],
  docLibraryId,
  showLoadMore = true,
  isLoadingMore = false,
  loadMoreOnClick,
  addDocumentOnClick,
  showPermissions = false,
}: SharedDmsDocumentDisplayProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  const isEditMode = useIsEditMode(docLibraryId);
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
        gap={2}
        mb={2}
      >
        {isEditMode && (
          <Paper key="addDocument" sx={{ height: 200 }}>
            <Button
              data-testid="add-document-button"
              sx={{ height: "100%" }}
              fullWidth
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={addDocumentOnClick}
            >
              {t(
                "Next:DocumentManagementSystem:AddDocumentListItem.addDocument"
              )}
            </Button>
          </Paper>
        )}

        {documents.map(
          ({
            id,
            name,
            description,
            parentId,
            fileId,
            fileName,
            documentLink,
            publishTime,
            availableTo,
            permissions,
            thumbnailId,
            thumbnailName,
          }) => (
            <DmsDocumentTile
              id={id}
              key={id}
              name={name}
              description={description}
              parentId={parentId}
              publishTime={publishTime}
              availableTo={availableTo}
              permissions={permissions ?? []}
              showPermissions={showPermissions}
              fileId={fileId}
              fileName={fileName}
              documentLink={documentLink}
              thumbnailId={thumbnailId}
              thumbnailName={thumbnailName ?? "test"}
            />
          )
        )}
      </Box>
      {showLoadMore && (
        <Box display="flex" justifyContent="center">
          <LoadingButton
            variant="outlined"
            startIcon={<ExpandMoreIcon />}
            loading={isLoadingMore}
            onClick={loadMoreOnClick}
          >
            {t("Next:DocumentManagementSystem:DocumentList.showMore")}
          </LoadingButton>
        </Box>
      )}
    </>
  );
}
