import Box from "@mui/material/Box";
import { FormEventHandler } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  IDocTranslationDto,
  LibraryFormValues,
  TranslationKey,
} from "features/DMS/entities";
import { ManagePaper } from "./ManagePaper";
import { LibraryFormDetails } from "./LibraryFormDetails";
import { CorporatePermissionManagement } from "./PermissionManagement";
import { LibraryFormDetailsSkeleton } from "./LibraryFormDetailsSkeleton";
import { DMSFormButtons } from "./DMSFormButtons";

export interface LibraryFormProps {
  mode?: "add" | "edit";
  isLoading?: boolean;
  translations?: IDocTranslationDto[];
  formMethods: UseFormReturn<LibraryFormValues>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  cancelOnClick: () => void;
  clearOnClick?: () => void;
  isSubmitting: boolean;
}

export function LibraryForm({
  mode = "add",
  isLoading = false,
  formMethods,
  onSubmit,
  cancelOnClick,
  clearOnClick,
  isSubmitting,
}: LibraryFormProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={onSubmit}>
        <ManagePaper
          sectionTitle={t(
            "Next:DocumentManagementSystem:LibraryAdd.Details.title"
          )}
        >
          {mode === "edit" && isLoading ? (
            <LibraryFormDetailsSkeleton />
          ) : (
            <LibraryFormDetails />
          )}
        </ManagePaper>
        <ManagePaper
          sectionTitle={t(
            "Next:DocumentManagementSystem:LibraryForm.ManagePermissions.title"
          )}
        >
          <CorporatePermissionManagement name="permissions" mode="library" />
        </ManagePaper>
        <DMSFormButtons
          mode={mode}
          // submitDisabled={!formMethods.formState.isValid}
          cancelOnClick={cancelOnClick}
          clearOnClick={clearOnClick}
          formType="LibraryForm"
          isSubmitting={isSubmitting}
        />
      </Box>
    </FormProvider>
  );
}

export const defaultValues = {
  menuItemId: "",
  translations: {
    [TranslationKey.En]: {
      transKey: TranslationKey.En,
      name: "",
      description: "",
      tags: [],
    },
  },
  permissions: [],
};
